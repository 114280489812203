/**
 * forwarder.js - URL parameter forwarding functionality for Lawbrokr
 */

import { isLocalStorageAvailable, matchesDomain, COOKIE_CONFIG } from './utils.js';
import { reportError } from './errors.js';

// Storage key for parameters
export const STORAGE_KEY = 'utm_forwarder_params';

// Default parameters to track with comprehensive platform support
export const DEFAULT_TRACK_PARAMS = [
  // Standard UTM Parameters
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  
  // Google - Enhanced Parameters
  'gclid',        // Google Ads Click ID
  'gbraid',       // Google Browser Reporting API ID
  'wbraid',       // Google Web Browser Reporting API ID
  'dclid',        // Google Display Click ID
  'gad_source',   // Google Ads Source
  'gad',          // Google Ads Parameter
  'gad_id',       // Google Ads ID
  'adgroupid',    // Google Ads Ad Group ID
  'glsid',        // Google Local Services ID
  'gmb_id',       // Google My Business ID
  'gmb_source',   // Google My Business Source
  'gbp_source',   // Google Business Profile Source
  'gls_source',   // Google Local Services Source
  'g_source',     // Generic Google Source
  'google_source', // Alternate Google Source
  'youtube_source', // YouTube
  'ytscid',         // YouTube click ID

  // Meta
  'fbclid',        // Facebook
  'fb_action_ids', // Facebook actions
  'fb_source',     // Facebook source
  'fb_ref',        // Facebook referral
  'igshid',        // Instagram
  'ig_source',     // Instagram source
  'th_source',     // Threads
  
  // Microsoft
  'msclkid',      // Microsoft Advertising
  'utm_msclkid',  // Microsoft Click ID
  
  // LinkedIn
  'trackingId',   // LinkedIn
  'li_fat_id',    // LinkedIn First-party
  'linkedin_share_id', // LinkedIn sharing
  'li_source',    // LinkedIn source
  
  // Twitter
  'twclid',
  'tw_source',
  'tw_ref',

  // TikTok
  'ttclid',       // TikTok
  'tt_source',    // TikTok source
  'tt_medium',    // TikTok medium
  'tt_content',   // TikTok content

  // Other social media platforms
  'ref_src',      // Reddit
  'ref_campaign', // Reddit campaign
  'reddit_source', // Reddit source
  'bsky_id',      // Bluesky
  
  // Other Platforms
  'vgo_ee',       // ActiveCampaign
  'ac_source',    // ActiveCampaign alternate
  'avvo_campaign', // Avvo
  'avvo_source',  // Avvo source
  'ltk_source',   // Linktree
  'yad_id',       // Yahoo
  'yahoo_source', // Yahoo source
  'yelp_source',  // Yelp
  'yelp_campaign' // Yelp campaign
];

// Source mapping for UTM detection
export const SOURCE_MAPPING = {
  // Google - Enhanced Mapping
  'gclid': 'google',
  'gbraid': 'google',
  'wbraid': 'google',
  'dclid': 'google',
  'gad_source': 'google',
  'gad': 'google',
  'gad_id': 'google',
  'adgroupid': 'google',
  'g_source': 'google',
  'google_source': 'google',
  // Google Local Services
  'glsid': 'google_local_services',
  'gls_source': 'google_local_services',
  'lsig': 'google_local_services',
  // Google My Business
  'gmb_id': 'google_my_business',
  'gmb_source': 'google_my_business',
  'gbp_source': 'google_my_business', // Business Profile (new name for GMB)
  'ludocid': 'google_my_business',
  // YouTube
  'youtube_source': 'youtube',
  'ytscid': 'youtube',
  
  // Meta
  'fbclid': 'facebook',
  'fb_action_ids': 'facebook',
  'fb_source': 'facebook',
  'fb_ref': 'facebook',
  'igshid': 'instagram',
  'ig_source': 'instagram',
  'th_source': 'threads',
  
  // Microsoft
  'msclkid': 'bing',
  'utm_msclkid': 'bing',
  
  // LinkedIn
  'trackingId': 'linkedin',
  'li_fat_id': 'linkedin',
  'linkedin_share_id': 'linkedin',
  'li_source': 'linkedin',

  // Twitter
  'twclid': 'twitter',
  'tw_source': 'twitter',
  'tw_ref': 'twitter',

  // TikTok
  'ttclid': 'tiktok',
  'tt_source': 'tiktok',
  'tt_medium': 'tiktok',
  'tt_content': 'tiktok',

  // Other social media platforms
  'ref_src': 'reddit',
  'ref_campaign': 'reddit',
  'reddit_source': 'reddit',
  'bsky_id': 'bluesky',
  
  // Other Platforms
  'vgo_ee': 'activecampaign',
  'ac_source': 'activecampaign',
  'avvo_campaign': 'avvo',
  'avvo_source': 'avvo',
  'ltk_source': 'linktree',
  'yad_id': 'yahoo',
  'yahoo_source': 'yahoo',
  'yelp_source': 'yelp',
  'yelp_campaign': 'yelp'
};

// UTM parameter-value mapping for additional source detection
export const UTM_VALUE_MAPPING = {
  'utm_campaign': {
    'gmb': 'google_my_business',
    'gls': 'google_local_services',
    'gad': 'google'
  }
};

// -----------------------------------------
// Parse query params and handle source inference
// -----------------------------------------
export function getQueryParams(config) {
  try {
    let params = {};
    const searchParams = new URLSearchParams(window.location.search);
    let hasUtmSource = false;
    let inferredSource = '';

    searchParams.forEach((value, key) => {
      const normalizedKey = key.toLowerCase();
      if (config.trackAllParams || config.trackParams.includes(normalizedKey)) {
        if (value) {
          params[normalizedKey] = value;
          if (normalizedKey === 'utm_source') {
            hasUtmSource = true;
          }
          if (!inferredSource && SOURCE_MAPPING[normalizedKey]) {
            inferredSource = SOURCE_MAPPING[normalizedKey];
          }
        }
      }
    });

    // Check UTM parameter-value mappings if no source inferred yet
    if (!hasUtmSource && !inferredSource) {
      Object.entries(UTM_VALUE_MAPPING).some(([utmParam, valueMap]) => {
        const paramValue = params[utmParam];
        if (paramValue && typeof paramValue === 'string') {
          const normalizedValue = paramValue.toLowerCase();
          const mappedSource = valueMap[normalizedValue];
          if (mappedSource) {
            inferredSource = mappedSource;
            return true;
          }
        }
        return false;
      });
    }

    if (!hasUtmSource && inferredSource) {
      params.utm_source = inferredSource;
      if (!params.utm_medium && inferredSource === 'google') {
        params.utm_medium = 'cpc';
      }
    }
    return params;
  } catch (err) {
    reportError('Error parsing query parameters', err, {
      search: window.location.search,
      trackAllParams: config.trackAllParams
    });
    return {};
  }
}

// -----------------------------------------
// Merge existing + new params
// -----------------------------------------
export function mergeParams(existing, incoming) {
  return { ...existing, ...incoming };
}

// -----------------------------------------
// Save params to storage with cookie fallback
// -----------------------------------------
export function saveParams(params) {
  if (!params || Object.keys(params).length === 0) {
    return;
  }

  const data = JSON.stringify(params);
  let success = false;

  if (isLocalStorageAvailable()) {
    try {
      localStorage.setItem(STORAGE_KEY, data);
      success = true;
    } catch (err) {
      reportError('Error saving to localStorage', err, {
        browserStorage: 'localStorage',
        dataSize: data.length,
        params: params
      });
    }
  }

  if (!success) {
    try {
      const expires = new Date();
      expires.setDate(expires.getDate() + COOKIE_CONFIG.days);
      
      const { path, domain, secure, sameSite } = COOKIE_CONFIG;
      const cookieString = `${STORAGE_KEY}=${encodeURIComponent(data)}; expires=${expires.toUTCString()}; path=${path}; domain=${domain}${secure ? '; secure' : ''}; samesite=${sameSite}`;
      
      document.cookie = cookieString;
    } catch (err) {
      reportError('Error saving to cookie', err, {
        browserStorage: 'cookie',
        dataSize: data.length,
        params: params,
        cookieConfig: COOKIE_CONFIG
      });
    }
  }
}

// -----------------------------------------
// Load params from storage with cookie fallback
// -----------------------------------------
export function loadParams() {
  let params = {};

  if (isLocalStorageAvailable()) {
    try {
      let stored = localStorage.getItem(STORAGE_KEY);
      if (stored) {
        params = JSON.parse(stored);
      }
    } catch (err) {
      reportError('Error loading from localStorage', err, {
        browserStorage: 'localStorage'
      });
    }
  }

  // If localStorage failed or was empty, try cookie
  if (Object.keys(params).length === 0) {
    try {
      let match = document.cookie.match(new RegExp('(^| )' + STORAGE_KEY + '=([^;]+)'));
      if (match) {
        params = JSON.parse(decodeURIComponent(match[2]));
      }
    } catch (err) {
      reportError('Error loading from cookie', err, {
        browserStorage: 'cookie',
        cookieString: document.cookie
      });
    }
  }

  return params;
}

// -----------------------------------------
// Check if a link's hostname ends with our domain
// e.g. lawfirm.lawbrokr.com
// -----------------------------------------
export function shouldAppendParams(link, config) {
  try {
    if (!link || typeof link.href !== 'string' || !link.href.trim()) {
      return false;
    }
    
    if (!link?.hostname || !config.domain) return false;
    return matchesDomain(link.hostname, config.domain);
  } catch (err) {
    reportError('Error checking hostname', err, {
      linkHostname: link?.hostname,
      configDomain: config.domain
    });
    return false;
  }
}

// -----------------------------------------
// Append parameters to a link's existing query
// -----------------------------------------
export function appendParams(link, params, isUpdatingLinks, setIsUpdatingLinks) {
  try {
    let url = new URL(link.href);
    let existingParams = new URLSearchParams(url.search);
    let updatedParams = new URLSearchParams('');
    let needsUpdate = false;

    // Copy all existing parameters
    existingParams.forEach(function(value, key) {
      updatedParams.append(key, value);
    });

    // Append our params only if they don't exist
    Object.keys(params).forEach(function(key) {
      let value = params[key];
      if (value !== undefined && value !== null && value !== '' && !existingParams.has(key)) {
        updatedParams.append(key, value);
        needsUpdate = true;
      }
    });

    // Add origin_url as the final parameter if it doesn't exist
    if (!existingParams.has('origin_url')) {
      let originUrl = window.location.origin + window.location.pathname;
      updatedParams.append('origin_url', originUrl);
      needsUpdate = true;
    }

    // Only update the URL if we actually need to add parameters
    if (needsUpdate) {
      isUpdatingLinks = true;
      url.search = updatedParams.toString();
      link.href = url.toString();
      
      // Return the flag so the caller can reset it after a short delay
      if (setIsUpdatingLinks) {
        // Reset flag after a short delay to allow mutation observer to process
        setTimeout(function() {
          setIsUpdatingLinks(false);
        }, 0);
      }
      
      return true;
    }
    
    return false;
  } catch (err) {
    reportError('Error appending parameters to link', err, {
      linkHref: link?.href,
      params: params,
      isUpdatingLinks: isUpdatingLinks
    });
    return false;
  }
}

// -----------------------------------------
// Main logic to capture, merge, rewrite links
// -----------------------------------------
export function forwardParams(config, isUpdatingLinks, setIsUpdatingLinks) {
  try {
    // Get current params from URL
    let currentParams = getQueryParams(config);
    let currentParamsExist = Object.keys(currentParams).length > 0;
    
    // Load previously stored params
    let storedParams = loadParams();
    
    // Merge with precedence to current params if they exist
    let merged = currentParamsExist ? 
      mergeParams(storedParams, currentParams) : 
      storedParams;
    
    // Only save if we have current params to prevent overwriting with empty values
    if (currentParamsExist) {
      saveParams(merged);
    }

    // Always process links, even if there are no stored/current params
    // This ensures origin_url is always added
    let allLinks = document.querySelectorAll('a[href]');
    let linksUpdated = false;
    
    Array.prototype.forEach.call(allLinks, function(link) {
      if (shouldAppendParams(link, config)) {
        const updated = appendParams(link, merged, isUpdatingLinks, setIsUpdatingLinks);
        if (updated) {
          linksUpdated = true;
        }
      }
    });
  } catch (err) {
    reportError('Error in forwardParams', err, {
      currentUrl: window.location.href,
      domain: config.domain,
      isUpdatingLinks: isUpdatingLinks
    });
  }
} 