/**
 * errors.js - Error handling and reporting for Lawbrokr
 */

// Internal state
let _honeybadger = null;
let _isEnabled = false;
let _domain = '';

/**
 * Initialize the error reporting system
 * @param {Object} config - The configuration object
 * @param {boolean} config.reportErrors - Whether to enable error reporting
 * @param {string} config.domain - The domain to report errors for
 */
export function init(config) {
  _honeybadger = window.Honeybadger || null;
  _isEnabled = config?.reportErrors && !!_honeybadger;
  _domain = config?.domain || '';
  
  // Handle case where Honeybadger is available after initialization
  if (config?.reportErrors && !_honeybadger) {
    let attempts = 0;
    const maxAttempts = 100; // 5 seconds at 50ms intervals
    
    const checkHoneybadger = function() {
      if (window.Honeybadger && !_honeybadger) {
        _honeybadger = window.Honeybadger;
        _isEnabled = true;
      } else if (!window.Honeybadger && attempts < maxAttempts) {
        attempts++;
        setTimeout(checkHoneybadger, 50);
      }
    };
    
    setTimeout(checkHoneybadger, 50);
  }
}

/**
 * Add a breadcrumb to the error trail
 * @param {string} message - The message to log
 * @param {Object} metadata - Additional metadata
 * @param {string} category - The category of the breadcrumb
 */
export function addBreadcrumb(message, metadata = {}, category = 'custom') {
  if (!_isEnabled || !_honeybadger) {
    return;
  }

  _honeybadger.addBreadcrumb(message, {
    metadata: metadata,
    category: category
  });
}

/**
 * Report an error to the error tracking system
 * @param {string} message - The error message
 * @param {Error} error - The error object
 * @param {Object} context - Additional context
 */
export function reportError(message, error, context = {}) {
  console.error(message, error);
  
  try {
    if (_isEnabled && _honeybadger) {
      addBreadcrumb('Error Context', {
        domain: _domain,
        location: window.location.href,
        timestamp: new Date().toISOString(),
        ...context
      }, 'error');

      _honeybadger.notify(error, {
        message: message
      });
    }
  } catch (err) {
    console.error('Error reporting error to Honeybadger', err);
  }
}

/**
 * Test function for Honeybadger integration
 */
export function testHoneybadger() {
  // Force enable reporting for test
  _honeybadger = window.Honeybadger;
  _isEnabled = true;
  
  try {
    // Add test breadcrumbs
    addBreadcrumb('Test Started', {
      timestamp: Date.now()
    }, 'log');

    addBreadcrumb('Test Parameters', {
      randomValue: Math.random(),
      testType: 'integration'
    }, 'custom');

    // Throw a test error
    const testError = new Error('Test error from Lawbrokr');
    testError.name = 'LawbrokrTestError';
    throw testError;
  } catch (error) {
    reportError('Testing Honeybadger integration', error, {
      test: true,
      timestamp: Date.now(),
      component: 'test'
    });
  }
} 