/**
 * utils.js - Utility and helper functions for Lawbrokr
 */

// Cookie configuration 
export const COOKIE_CONFIG = {
  days: 30,
  path: '/',
  domain: window.location.hostname.split('.').slice(-2).join('.'),
  secure: window.location.protocol === 'https:',
  sameSite: 'Lax' 
};

// -----------------------------------------
// Check if localStorage is available
// -----------------------------------------
export function isLocalStorageAvailable() {
  try {
    const test = '__storage_test__';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

// -----------------------------------------
// Document and Observer Status Utilities
// -----------------------------------------
export function isDocumentReady() {
  return (
    document.body !== null &&
    document.readyState !== 'loading'
  );
}

// -----------------------------------------
// Get document state
// -----------------------------------------
export function getDocumentState() {
  return {
    readyState: document.readyState,
    hasBody: document.body !== null,
    isConnected: document.body?.isConnected ?? null,
    hasParentNode: document.body?.parentNode !== null || null,
    parentNodeType: document.body?.parentNode?.nodeName || null
  };
}


// Helper function to check if a domain matches target domain
export function matchesDomain(urlHostname, configDomain) {
  urlHostname = urlHostname.toLowerCase();
  configDomain = configDomain.toLowerCase();
  
  // Handle wildcard domain pattern
  if (configDomain.startsWith('*.')) {
    const baseDomain = configDomain.slice(2); // Remove *. from the start
    const [subdomain, ...rest] = urlHostname.split('.');
    const urlBaseDomain = rest.join('.');
    
    // Don't match www or app subdomains
    if (subdomain === 'www' || subdomain === 'app') {
      return false;
    }
    
    return urlBaseDomain === baseDomain;
  }
  
  // Handle exact domain match (including subdomains)
  return urlHostname === configDomain || urlHostname.endsWith(`.${configDomain}`);
} 