/*
 * Lawbrokr
 *
 * Usage:
 * 1. Place <script src="//cdn.lawbrokr.com/js/latest/lawbrokr.min.js"></script> in the <head> (not async).
 * 2. Then call:
 *    <script>
 *      Lawbrokr.init({
 *        domain: 'lawfirm.lawbrokr.com',
 *        trackParams: ['utm_source','utm_medium','gclid','fbclid'] // optional
 *      });
 *    </script>
 *
 * The script will wait for DOMContentLoaded and then rewrite links
 * that match the domain/subdomain, appending stored query params.
 */

/**
 * @fileoverview Main entry point for Lawbrokr script.
 * @suppress {checkVars}
 * @suppress {checkTypes}
 * @suppress {missingProperties}
 * @suppress {extraRequire}
 */

// Import modules
import * as utils from './utils.js';
import * as forwarder from './forwarder.js';
import * as errors from './errors.js';

(function(global) {
  // Default Lawbrokr config
  const DEFAULT_CONFIG = {
    domain: '*.lawbrokr.com',
    trackParams: forwarder.DEFAULT_TRACK_PARAMS,
    trackAllParams: false,
    reportErrors: true,
    enableIframe: false
  };

  // Global Lawbrokr object
  var Lawbrokr = {};

  // Internal config
  var config = {
    domain: '*.lawbrokr.com',
    trackParams: [],
    trackAllParams: false,
    reportErrors: true,
    enableIframe: false
  };

  // Global variables
  let isUpdatingLinks = false;

  // Function to set isUpdatingLinks flag
  function setIsUpdatingLinks(value) {
    isUpdatingLinks = value;
  }

  // Public API to initialize Lawbrokr script
  Lawbrokr.init = function(userConfig = {}) {
    config = { ...DEFAULT_CONFIG, ...userConfig };
    
    // Normalize trackParams if provided
    if (userConfig.trackParams) {
      config.trackParams = Array.isArray(userConfig.trackParams)
        ? userConfig.trackParams.map(p => p.toLowerCase())
        : forwarder.DEFAULT_TRACK_PARAMS;
    }

    // Initialize error reporting
    errors.init(config);

    let isInitialized = false;
    let pendingTimeout = null;
    let observer = null;

    function handleForwardParams() {
      if (pendingTimeout) {
        clearTimeout(pendingTimeout);
      }
      pendingTimeout = setTimeout(function() {
        forwarder.forwardParams(config, isUpdatingLinks, setIsUpdatingLinks);
        pendingTimeout = null;
      }, 250);
    }

    function cleanup() {
      try {
        if (observer) {
          observer.disconnect();
        }
        if (pendingTimeout) {
          clearTimeout(pendingTimeout);
        }
      } catch (err) {
        errors.reportError('Error in cleanup function', err, {
          hasObserver: !!observer,
          hasPendingTimeout: !!pendingTimeout
        });
      }
    }

    function initForwarding() {
      // Prevent multiple initializations
      if (isInitialized) {
        return;
      }
      isInitialized = true;

      // Initial parameter forwarding
      forwarder.forwardParams(config, isUpdatingLinks, setIsUpdatingLinks);

      // Set up mutation observer for dynamic content
      observer = new MutationObserver(function(mutations) {
        // Skip if we're currently updating links
        if (isUpdatingLinks) {
          return;
        }

        let shouldUpdate = false;
        try {
          shouldUpdate = mutations.some(function(mutation) {
            try {
              // For added nodes, check if any new links match our domain
              if (mutation.addedNodes.length > 0) {
                for (let i = 0; i < mutation.addedNodes.length; i++) {
                  let node = mutation.addedNodes[i];
                  // Check if the node itself is a matching link
                  if (node.nodeType === 1 && node.tagName === 'A' &&
                      forwarder.shouldAppendParams(node, config)) {
                    return true;
                  }
                  // Check if the node contains any matching links
                  if (node.nodeType === 1 && node.getElementsByTagName) {
                    let links = node.getElementsByTagName('A');
                    for (let j = 0; j < links.length; j++) {
                      if (forwarder.shouldAppendParams(links[j], config)) {
                        return true;
                      }
                    }
                  }
                }
              }
              
              // Only care about href changes on matching links
              if (mutation.type === 'attributes' && 
                  mutation.attributeName === 'href' && 
                  mutation.target.tagName === 'A' && 
                  forwarder.shouldAppendParams(mutation.target, config)) {
                return true;
              }
              
              return false;
            } catch (err) {
              errors.reportError('Error processing mutation', err, {
                mutationType: mutation.type,
                attributeName: mutation.attributeName,
                targetTagName: mutation.target?.tagName
              });
              return false;
            }
          });
        } catch (err) {
          errors.reportError('Error processing mutations array', err, {
            mutationsLength: mutations?.length
          });
        }

        if (shouldUpdate) {
          handleForwardParams();
        }
      });

      // Start mutation observer, ensuring document is fully loaded
      try {
        let retryCount = 0;
        const MAX_RETRIES = 100; // 5s total retry time
        
        function setupObserver() {
          if (retryCount > MAX_RETRIES) {
            console.warn('Max observer setup retries exceeded');
            return;
          }

          if (!utils.isDocumentReady()) {
            retryCount++;
            setTimeout(setupObserver, 50);
            return;
          }

          try {
            observer.observe(document.body, {
              childList: true,
              subtree: true,
              attributes: true,
              attributeFilter: ['href']
            });
          } catch (obsErr) {
            if (retryCount >= MAX_RETRIES) {
              errors.addBreadcrumb('Max observer error retries exceeded', {
                ...utils.getDocumentState()
              }, 'custom');
              throw obsErr;
            }
            retryCount++;
            setTimeout(setupObserver, 50);
          }
        }

        setupObserver();
      } catch (err) {
        errors.reportError('Error setting up mutation observer', err, {
          ...utils.getDocumentState()
        });
      }

      // Handle standard navigation events
      try {
        window.addEventListener('load', handleForwardParams);
        document.addEventListener('DOMContentLoaded', handleForwardParams);

        // jQuery check for WordPress/Squarespace support
        if (typeof window.jQuery !== 'undefined' && typeof window.jQuery.fn !== 'undefined' && typeof window.jQuery.fn.jquery !== 'undefined') {
          try {
            const jQueryInstance = window.jQuery;
            if (typeof jQueryInstance.fn.on === 'function') {
              jQueryInstance(document).on('ajaxComplete', handleForwardParams);
            }
          } catch (err) {
            console.warn('jQuery ajaxComplete handler not available', err);
          }
        }

        // Handle SPA support
        window.addEventListener('popstate', handleForwardParams);
        window.addEventListener('unload', cleanup);
      } catch (err) {
        errors.reportError('Error setting up event listeners', err, {
          documentReadyState: document.readyState,
          hasJQuery: !!window.jQuery
        });
      }
    }

    // Initialize based on document state
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', function() {
        setTimeout(initForwarding, 500);
      });
    } else {
      setTimeout(initForwarding, 500);
    }

    // Backup initialization for edge cases
    setTimeout(function() {
      if (!isInitialized) {
        initForwarding();
      }
    }, 2000);
  };

  // Add test function to public API
  Lawbrokr.testHoneybadger = function() {
    errors.testHoneybadger();
  };

  // Expose the global Lawbrokr object
  global.Lawbrokr = Lawbrokr;

})(window);
